import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import WhatIsDigitalWalletMd from '../../assets/images/WhatIsDigitalWalletMd.png'
import WhatIsDigitalWalletLg from '../../assets/images/WhatIsDigitalWalletLg.png'
import WhatIsDigitalWalletXl from '../../assets/images/WhatIsDigitalWalletXl.png'

export const Section = styled.section`
    background: #ff7a00;

    @media (min-width: ${breakpoints.md}){
      border-radius: 0px 215px 0px 0px;
      background-image:url(${WhatIsDigitalWalletMd});
      background-repeat: no-repeat;
      background-size: contain;
      min-height: 433px;
    }
    @media (min-width: ${breakpoints.lg}){
      background-image: url(${WhatIsDigitalWalletLg});
      background-repeat: no-repeat;
      background-size: contain;
    }
    @media (min-width: ${breakpoints.xl}){
      background-image: url(${WhatIsDigitalWalletXl});
      background-repeat: no-repeat;
      background-size: cover;
      max-height: 433px;
      background-position-y: bottom;
    }

    .play-image{
      max-height: 70px;
      max-width: 70px;
      height: 70px;
      width: 70px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;

      @media (min-width: ${breakpoints.md}){
        left: 130px;
      }

      @media (min-width: ${breakpoints.lg}){
        left: 159px;
      }
      @media (min-width: ${breakpoints.xl}){
        left: 138px;
      }
      @media (min-width: ${breakpoints.xxxl}){
        left: 0;
      }
    }
    .relative{
      position: relative;
    }

`
