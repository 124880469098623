import React from 'react'
import Breadcrumb from 'src/components/Breadcrumb'
import LinkTo from 'src/components/LinkTo/LinkTo'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import pageQuery from '../../pageQuery'

import { Section } from './style'
import ImageWebp from 'src/components/ImageWebp'

const Hero = () => {
  const data = pageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <Section className='pt-3 pb-5 pt-lg-0 d-flex align-items-lg-center'>
      <div className='container pb-lg-5'>
        <div className='row'>
          <div className='col-12'>
            <Breadcrumb
              sectionName='Carteiras Digitais e pagamento por aproximação é Inter'
              text='Carteiras Digitais'
              link='/pra-voce/cartoes/carteiras-digitais'
              type='pra você' product='Cartão de Débito e Crédito'
              styles='breadcrumb mb-md-4'
            />
          </div>
        </div>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h1 className='fs-32 lh-40 fs-xl-48 lh-xl-60 text-grayscale--500 fw-600 mb-3'>
              Carteiras Digitais e pagamento por aproximação é Inter
            </h1>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400'>
              Faça pagamentos por aproximação e utilize seu cartão Inter nas principais Carteiras Digitais para simplificar seu dia a dia.
            </p>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400'>
              E o melhor: acumule pontos para trocar por benefícios no Inter Loop!
            </p>
          </div>
          <div className='col-12 col-md-6 col-xl-5 order-first order-md-last mb-4 mb-md-0'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/hero-1440/image.webp'
              altDescription='Mulher sorridente realizando o pagamento por aproximação utilizando um celular próximo de uma maquininha de cartão.'
              arrayNumbers={[ 1, 2, 3, 4, 5 ]}
              arrayNumbersHeight={[ 1, 2, 3, 4, 5 ]}
            />
          </div>
          <LinkTo
            to='#simplifiquePagamento'
            sendDatalayerEvent={sendDatalayerEvent}
            section='dobra_01'
            elementName='Conheça mais as carteiras digitais'
            sectionName=' Carteiras Digitais e pagamento por aproximação é Inter'
          >
            <div className='text-center icon '>
              <p className='fs-14 lh-17 fs-md-18 lh-md-22 text-orange--extra fw-600 mb-0'>
                Conheça mais as carteiras digitais
              </p>
              <OrangeDsIcon size='MD' color='#ff7a00' icon='chevron-down' />
            </div>
          </LinkTo>
        </div>
      </div>
    </Section>
  )
}

export default Hero
