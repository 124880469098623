import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const SectionContent = styled.section`
    min-height: 449px;

    @media (min-width: ${breakpoints.md}){
      min-height: 380px;
    }



    @media (min-width: ${breakpoints.xl}){
      min-height: 546px;
    }

    .content-image{
       width: 219px;

  }


`
export const Circle = styled.div`
   background: #161616;
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    margin-right: 24px;

`
