import styled from 'styled-components'

export const Section = styled.section`

.paypal-image{
    width: 162px;

    img{
      object-fit:contain !important ;
    }
  }

`

export const Circle = styled.div`
background: #161616;
 width: 24px;
 min-width: 24px;
 height: 24px;
 border-radius: 50%;
 display: flex;
 align-items: center;
 justify-content: center;
 color: #ffffff;
 margin-right: 24px;



`
