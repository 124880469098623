import React from 'react'

import { Circle, Section } from './style'

import Img from 'gatsby-image'

import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import pageQuery from '../../../pageQuery'

const PayPal = () => {
  const data = pageQuery()
  return (
    <Section>
      <div>
        <div className='paypal-image mr-5 px-3'>
          <Img fluid={data.payPalLogo.fluid} />
        </div>
      </div>
      <p className='fs-14 lh-17 fs-md-18 lh-md-22 text-grayscale--500 mt-4 fw-400 mb-4'>É a carteira digital online que você pode acessar via app ou site. Ao contrário das outras, o PayPal não permite o pagamento por aproximação, apenas online. </p>
      <hr />
      <div className='d-flex align-items-center my-4'>
        <h3 className='fs-16 lh-19 text-grayscale--500 fw-700  mb-0'>
          Como cadastrar seu cartão Inter no PayPal
        </h3>
      </div>
      <div className='d-flex align-items-center mb-3'>
        <Circle>
          1
        </Circle>

        <p className='fs-14 lh-17 text-grayscale--400 mb-0'>Acesse sua conta PayPal;  </p>
      </div>
      <div className='d-flex align-items-center mb-4'>
        <Circle>
          2
        </Circle>

        <p className='fs-14 lh-17 text-grayscale--400 mb-0'>No seu perfil, selecione a opção “Carteira”; </p>
      </div>
      <div className='d-flex align-items-center mb-4'>
        <Circle>
          3
        </Circle>

        <p className='fs-14 lh-17 text-grayscale--400 mb-0'>Toque em “Adicionar um cartão”; </p>
      </div>
      <div className='d-flex align-items-center mb-4'>
        <Circle>
          4
        </Circle>

        <p className='fs-14 lh-17 text-grayscale--400 mb-0'>Insira as informações do seu cartão Inter e pronto!</p>
      </div>
    </Section>

  )
}

export default PayPal
