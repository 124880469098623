import React from 'react'

import { Circle, Section } from './style'

import Img from 'gatsby-image'

import pageQuery from '../../../pageQuery'

const SamgungPay = () => {
  const data = pageQuery()
  return (
    <Section>
      <div className='samsung-image mr-5'>
        <Img fluid={data.sangungPayLogo.fluid} />
      </div>
      <p className='fs-14 lh-17 fs-md-18 lh-md-22 text-grayscale--500 mt-4 fw-400 mb-4'>É a carteira digital para smartphones Samsung.</p>
      <hr />
      <div className='d-flex align-items-center my-4'>
        <h3 className='fs-16 lh-19 text-grayscale--500 fw-700 mb-0'>
          Como cadastrar seu cartão Inter no Samsung Pay
        </h3>
      </div>
      <div className='d-flex align-items-center mb-4'>
        <Circle>
          1
        </Circle>

        <p className='fs-14 lh-17 text-grayscale--400 mb-0'>Acesse o app Samsung Pay;</p>
      </div>
      <div className='d-flex align-items-center mb-4'>
        <Circle>
          2
        </Circle>

        <p className='fs-14 lh-17 text-grayscale--400 mb-0'>Escolha a forma de verificação para pagar (senha ou digital); </p>
      </div>
      <div className='d-flex align-items-center mb-4'>
        <Circle>
          3
        </Circle>

        <p className='fs-14 lh-17 text-grayscale--400 mb-0'>Toque em “Adicionar cartão” ou “+ cartões de pagamento”; </p>
      </div>
      <div className='d-flex align-items-center mb-4'>
        <Circle>
          4
        </Circle>

        <p className='fs-14 lh-17 text-grayscale--400 mb-0'>Escaneie ou insira manualmente os dados do seu cartão e siga as instruções para finalizar. </p>
      </div>
    </Section>
  )
}

export default SamgungPay
