import React from 'react'
import Layout from 'src/layouts/BaseLayout'

// SECTIONS
import Hero from './_section/_hero/_index'
import SimplifyPayment from './_section/_simplify-payment/_index'
import HowToPay from './_section/_how-to-pay/_index'
import ProgramaDeBeneficios from './_section/_beneficios/_index'
import WhatIsDigitalWallet from './_section/_what-is-digital-wallet/_index'
import IphoneExclusiveDigitalWallet from './_section/_iphone-exclusive-digital-wallet/_index'
import PaymentApproach from './_section/_payment-approach/_index'
import GooglePayIsInter from './_section/google-pay-is-inter/_index'
import Faq from './_section/_faq/_index'

import pageContext from './pageContext.json'

const DigitalWallets = () => {
  return (
    <Layout pageContext={pageContext}>
      <Hero />
      <SimplifyPayment />
      <HowToPay />
      <ProgramaDeBeneficios />
      <WhatIsDigitalWallet />
      <GooglePayIsInter />
      <IphoneExclusiveDigitalWallet />
      <PaymentApproach />
      <Faq />
    </Layout>
  )
}

export default DigitalWallets
