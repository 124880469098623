import React from 'react'

import { SectionContent, Circle } from './style'

import Img from 'gatsby-image'

import pageQuery from '../../../pageQuery'

const GooglePay = () => {
  const data = pageQuery()
  return (
    <SectionContent>
      <div>
        <div className='content-image mr-5'>
          <Img fluid={data.paymentsWhatsApp.fluid} />
        </div>
      </div>
      <p className='fs-14 lh-17 fs-md-18 lh-md-22 text-grayscale--500 mt-4 fw-400 mb-4'>
        Faça pagamentos no WhatsApp para simplificar sua forma de transferir dinheiro para amigos, ou realizar compras em uma loja que possui perfil de empresa no aplicativo.
      </p>
      <hr />
      <div className='d-flex align-items-center my-4'>
        <h3 className='fs-16 lh-19 text-grayscale--500 fw-600  mb-0'>
          Como fazer pagamentos no WhatsApp com o Inter
        </h3>
      </div>
      <div>
        <div className='d-flex align-items-center mb-4'>
          <Circle>
            1
          </Circle>

          <p className='fs-14 lh-17 text-grayscale--400 mb-0'>
            Abra a conversa com o contato para o qual você deseja enviar o dinheiro, e seguida, toque em “Anexar” e depois “Pagamento”;
          </p>
        </div>
        <div className='d-flex align-items-center mb-4'>
          <Circle>
            2
          </Circle>

          <p className='fs-14 lh-17 text-grayscale--400 mb-0'>
            Crie uma senha de 6 dígitos e defina se irá utilizá-la para autenticar suas transferências. Ou, utilize a biometria do seu dispositivo e siga as instruções para concluir a configuração.
          </p>
        </div>
        <div className='d-flex align-items-center mb-4'>
          <Circle>
            3
          </Circle>

          <p className='fs-14 lh-17 text-grayscale--400 mb-0'>
            Verifique o cartão cadastrado e siga com o pagamento.
          </p>
        </div>
        <p className='fs-14 lh-17 text-grayscale--400 mb-4'>
          Se preferir, você pode configurar os recursos de pagamento nos ajustes do WhatsApp. Para isso, toque em “Pagamentos” {'>'} “Facebook Pay” {'>'} “Continuar”.
        </p>
        <p className='fs-14 lh-17 text-grayscale--400 mb-0'>
          O recurso de pagamentos no WhatsApp é oferecido pelo Facebook Pay e processado pelo Facebook Pagamentos e pela Cielo.
        </p>
      </div>
    </SectionContent>
  )
}

export default GooglePay
