import React from 'react'
import * as S from './style'
import data from './data.json'
import IntercoOrangeIcon from 'src/components/UI/MarkdownIcon/InterCoIcons'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { Link } from 'gatsby'

export default function ProgramaDeBeneficios () {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.Wrapper>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 d-md-flex flex-column align-items-center'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500 sora'>Programa de benefícios Inter</h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-20 lh-xl-22 text-grayscale-400 mt-3 mb-0 text-center'>
              Com o Inter Loop, você acumula pontos pelas compras no cartão de crédito para trocar por recompensas exclusivas.
            </p>
            <p className='fs-10 fs-md-12 fs-lg-14 lh-12 lh-md-14 lh-lg-18 text-grayscale-400 mt-3 mb-4 mb-md-5 text-center'>
              Para ganhar pontos, é necessário ter o débito automático de fatura ativo.
            </p>
          </div>
          <div className='col-12 d-md-flex justify-content-center'>
            {
                data.map((item: {id: number; text: string; icon: string}) => (
                  <div key={item.id}>
                    <S.List className='d-flex d-block d-md-none'>
                      <IntercoOrangeIcon icon={item.icon} size='MD' color='#161616' />
                      <S.ListTitle className='sora fs-16 lh-20 text-grayscale--500 fw-600'>
                        {item.text}
                      </S.ListTitle>
                    </S.List>
                    { item.id !== 4 && <hr className='d-block d-md-none' /> }
                    <S.Card className='d-none d-md-block'>
                      <IntercoOrangeIcon icon={item.icon} size='MD' color='#161616' />
                      <S.ListTitle className='sora fs-16 fs-lg-20 lh-20 lh-lg-24 text-grayscale--500 fw-600 mt-4 ml-0'>
                        {item.text}
                      </S.ListTitle>
                    </S.Card>
                  </div>
                ))
              }
          </div>
          <div className='col-12 text-center'>
            <Link
              to='/pra-voce/cartoes/programa-de-pontos/'
              className='btn btn--lg btn-orange--extra fs-14 fw-700 rounded-3 ml-lg-0 text-none mt-3 link-button'
              onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    element_action: 'click button',
                    element_name: 'Conhecer o Inter Loop',
                    section_name: 'Programa de benefícios Inter',
                    redirect_url: window.location.href + '/pra-voce/cartoes/programa-de-pontos/',
                    c_page: window.location.href,
                  })
              }}
            >
              Conhecer o Inter Loop
            </Link>
          </div>
        </div>
      </div>
    </S.Wrapper>
  )
}
