import styled from 'styled-components'
import { orange, white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`


.termo {
  font-style: italic;
}

img {
  width: 310px;
  height: 216px;

  @media (min-width: ${breakpoints.md}){
    width: 369px;
    height: 280px;
  }

  @media (min-width: ${breakpoints.lg}){
    width: 417px;
    height: 290px;
  }

  @media (min-width: ${breakpoints.xl}){
    width: 608px;
    height: 413px;
  }
}

`

export const Button = styled.a`
  background:  ${orange.extra};
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  color: ${white};
  margin-top: 32px;
  display: flex ;
  align-items: center;
  justify-content: center;

  &:focus, &:hover{
    outline: none;
    color: ${white};
  }

`
