import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  position: relative;
  min-height: 770px;

  @media (min-width: ${breakpoints.md}){
    min-height: 550px;
  }

  @media (min-width: ${breakpoints.lg}){
    min-height: calc(100vh - 73px);
  }

  img {
    width: 312px;
    height: 312px;


    @media (min-width: ${breakpoints.md}){
      width: 253px;
      height: 253px;
    }

    @media (min-width: ${breakpoints.lg}){
      width: 295px;
      height: 295px;
    }

    @media (min-width: ${breakpoints.xl}){
      width: 456px;
      height: 456px;
    }
  }

  .breadcrumb{
    span{
      color: #6A6C72 !important;
    }
    a{
      font-weight: 600;
      color: #6A6C72 !important;
    }
  }

  .icon{
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
`
