import styled from 'styled-components'
import BgImage from '../../assets/images/PaymentApproach.png'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
    background-color: #F5F6FA;
    background-image: url(${BgImage});
    min-height: 449px;

    @media (min-width: ${breakpoints.md}){
      min-height: 380px;
    }



`
export const Button = styled.button`
    background: #FF7A00;
    border-radius: 8px;
    font-size: 14px;
    line-height: 17px;
    width: 456px;
    height: 48px;
    border: none;
    color: #fff;
    font-weight: 600;

    &:focus{
      outline: none;
    }

    @media (min-width: ${breakpoints.xl}){
      width: 456px;
    }

`
export const Link = styled.a`
     background: #FF7A00;
    border-radius: 8px;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
    height: 48px;
    border: none;
    color: #fff;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover{
      outline: none;
      text-decoration: none;
      color: #fff;
    }

    &:focus{
      outline: none;
    }

`
