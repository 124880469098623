import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      digitalWallets: imageSharp(fluid: { originalName: { regex: "/digitalWallets/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      howToPay: imageSharp(fluid: { originalName: { regex: "/howToPay/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      whatIsDigitalWallet: imageSharp(fluid: { originalName: { regex: "/WhatIsDigitalWalletSm/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      iphoneLogo: imageSharp(fluid: { originalName: { regex: "/ExclusiveIphoneLogo/" }}) {
        fluid(maxWidth: 118, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      googlePayLogo: imageSharp(fluid: { originalName: { regex: "/GooglePayDigitalWallet/" }}) {
        fluid(maxWidth: 118, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      sangungPayLogo: imageSharp(fluid: { originalName: { regex: "/SamsungPay/" }}) {
        fluid(maxWidth: 261, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      payPalLogo: imageSharp(fluid: { originalName: { regex: "/PayPal/" }}) {
        fluid(maxWidth: 118, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      paymentsWhatsApp: imageSharp(fluid: { originalName: { regex: "/PaymentsWhatsApp/" }}) {
        fluid(maxWidth: 118, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      WhatIsDigitalWalletPlay: imageSharp(fluid: { originalName: { regex: "/WhatIsDigitalWalletPlay/" }}) {
        fluid(maxWidth: 70, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      googleIsInter: imageSharp(fluid: { originalName: { regex: "/googleIsInter/" }}) {
        fluid(maxWidth: 533, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
