import React from 'react'

import Img from 'gatsby-image'

import { Section, Circle, AppleContante } from './style'

import pageQuery from '../../../pageQuery'

const IphoneExclusiveDigitalWallet = () => {
  const data = pageQuery()
  return (
    <Section>
      <div className='content-image mr-5'>
        <Img fluid={data.iphoneLogo.fluid} />
      </div>
      <p className='fs-14 lh-17 fs-md-18 lh-md-22 text-grayscale--500 mt-4 fw-400 mb-4'>É a carteira digital exclusiva para quem tem iPhone. Para cadastrar o seu cartão, você precisa ter o aplicativo “Wallet”. </p>
      <hr />
      <div className='d-flex align-items-center my-5'>
        <h3 className='fs-16 lh-19 text-grayscale--500 fw-700 mb-0'>
          Como cadastrar seu cartão Inter no Apple Pay
        </h3>
      </div>
      <AppleContante>
        <h4 className='fs-16 lh-19 text-grayscale--500 fw-600 mb-4'>Pelo Super App do Inter </h4>
        <div className='d-flex align-items-center mb-4'>
          <Circle>
            1
          </Circle>
          <p className='fs-14 lh-17 text-grayscale--400 mb-0'>Acesse o app do Inter; </p>
        </div>
        <div className='d-flex align-items-center mb-4'>
          <Circle>
            2
          </Circle>
          <p className='fs-14 lh-17 text-grayscale--400 mb-0'>Abra as configurações do seu cartão e vá em “Apple Pay”;  </p>
        </div>
        <div className='d-flex align-items-center mb-4'>
          <Circle>
            3
          </Circle>
          <p className='fs-14 lh-17 text-grayscale--400 mb-0'>Toque em “Adicionar ao Apple Wallet” e siga as instruções. </p>
        </div>
        <h4 className='fs-16 lh-19 text-grayscale--500 fw-600 mb-4 mt-4'>Pelo app Wallet</h4>
        <div className='d-flex align-items-center mb-3'>
          <Circle>
            1
          </Circle>
          <p className='fs-14 lh-17 text-grayscale--400 mb-0'>Acesse o app Wallet no seu iPhone;</p>
        </div>
        <div className='d-flex align-items-center mb-3'>
          <Circle>
            2
          </Circle>
          <p className='fs-14 lh-17 text-grayscale--400 mb-0'>Toque no símbolo “+” no canto superior direito;</p>
        </div>
        <div className='d-flex align-items-center mb-3'>
          <Circle>
            3
          </Circle>
          <p className='fs-14 lh-17 text-grayscale--400 mb-0'>Escaneie ou insira manualmente os dados do seu cartão e siga as instruções para finalizar.</p>
        </div>
      </AppleContante>
    </Section>
  )
}

export default IphoneExclusiveDigitalWallet
