import React from 'react'

import FAQ from 'src/components/Faq'

import pageContext from '../../pageContext.json'

const Faq = () => {
  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-40 lh-50 text-grayscale--400 mb-4'>Perguntas frequentes</h2>

            <FAQ answerData={pageContext.structuredData.faq} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} />

          </div>
        </div>
      </div>
    </section>
  )
}

export default Faq
