import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`

  .f-sora{
    font-family: Sora;
  }

  .content-image{
    width: 118px;
    display: block;
  }

`
export const Circle = styled.div`
   background: #ff7a00;
    width: 32px;
    min-width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    margin-right: 24px;

`
export const AppleContante = styled.div`


    @media (min-width: ${breakpoints.xl}){
      min-height: 137px;
    }

`
export const Content = styled.div`
      background:#F5F6FA;
      border-radius:8px;
      width:100%;
      padding: 16px;
      margin-bottom: 24px;

      @media (min-width: ${breakpoints.lg}){
        padding:48px;
      }


      &.line-1{

        @media (min-width: ${breakpoints.md}){
          min-height:764px;
        }
      }
      &.line-2{

        @media (min-width: ${breakpoints.md}){
          min-height:536px;
        }
        @media (min-width: ${breakpoints.lg}){
          min-height:634px;
        }
      }
      &.line-3{

        @media (min-width: ${breakpoints.md}){
          min-height:563px ;
        }
      }

`
